import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { App } from './components/app/App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

ReactDOM.render(<App />, document.getElementById('root'));

ReactGA.initialize('UA-139332644-2');

serviceWorker.unregister();
